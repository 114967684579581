<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('storeChangeLogList') }}</h2>
      <grid-view-table
        :id="'store-change-log'"
        :columns="columns"
        :path="'store-change-log'"
        :extra-params="extraParams"
        :default-sort="'updatedAt,DESC'"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/store-change-log';

  export default {
    name: 'StoreChangeLogList',
    components: {
      GridViewTable,
    },
    props: {
      storeId: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        columns,
      }
    },
    computed: {
      extraParams: function () {
        return `&join=store&join=updatedBy&filter=store.id||eq||${this.storeId}`
      }
    },
  }
</script>
