import moment from 'moment';

export const columns = [
  {
    label: 'updatedAt',
    field: 'updatedAt',
    width: '35%',
    formatRowValue: true,
    formatFn: (value) => {
      return moment(value.updatedAt).format('DD.MM.YYYY HH:mm:ss');
    },
    filterOptions: {
      enabled: false,
      date: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'updatedBy',
    field: 'updatedBy',
    width: '45%',
    formatRowValue: true,
    formatFn: (value) => {
      return value && value.updatedBy ? value.updatedBy.name : '-';
    },
    filterOptions: {
      enabled: false,
      date: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      {
        type: 'view',
        label: '',
        class: 'fa fa-eye',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {
              'storeChangeLogId': data.id,
            }
          },
          nameResolver: () => {
            return 'ViewStoreChangeLog';
          }
        },
      },
    ]
  },
]
